import * as _ from 'lodash'
import { ResponsiveLayout } from '@wix/platform-editor-sdk'
import { FIELD_COMPONENT_TYPES, FormPlugin, FieldCollectionType, FieldRenderConfigType } from '@wix/forms-common'
import { IGeneralField } from './general-field'
import {
  CATEGORIES,
  DEFAULT_CATEGORIES,
} from '../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldsStore } from './fields-store'

export interface FieldExtraData {
  role: string
  connectionConfig?: {
    crmType: string
    crmLabel?: string
    crmTag?: string
    customFieldId?: string
    label?: string
  }
  props?: any
  data?: any
  layout?: any
  layoutResponsive?: ResponsiveLayout
}

export interface FieldProperties {
  collectionFieldType?: FieldCollectionType | false
  componentType: FIELD_COMPONENT_TYPES
  extraData: FieldExtraData
}

export interface FieldRenderConfigStructure {
  isMandatory?: boolean
  crmSync?: FieldRenderConfigType
  connectPanelRegistrationFooter?: FieldRenderConfigType
  required?: FieldRenderConfigType
  internalName?: FieldRenderConfigType
  checkedByDefault?: FieldRenderConfigType
  isEditable?: boolean
  addFieldPanelData?: AddFieldPanelData
}

export interface AddFieldPanelData {
  category: CATEGORIES | DEFAULT_CATEGORIES
  isPremium?: boolean
  dependsOn?: string[]
  hideTranslationPostfix?: boolean
  showTooltip?: boolean
  subCategory?: CATEGORIES | DEFAULT_CATEGORIES
}

export type FieldRenderConfig = { [key in FormPlugin]?: FieldRenderConfigStructure }

// TODO: Merge this with default form builder config render and merge unused flows (AKA === required.ENABLED/REMOVE? why both?)
const DEFAULT_FIELD_RENDER_CONFIG: FieldRenderConfigStructure = {
  isEditable: true,
  isMandatory: false,
  crmSync: FieldRenderConfigType.ENABLED,
  connectPanelRegistrationFooter: FieldRenderConfigType.REMOVE,
  required: FieldRenderConfigType.ENABLED,
  internalName: FieldRenderConfigType.ENABLED,
  checkedByDefault: FieldRenderConfigType.ENABLED,
  addFieldPanelData: { category: DEFAULT_CATEGORIES.none }
}

export type PluginsFieldExtraData = { [key in FormPlugin]?: Partial<FieldExtraData> }

export const getFieldRenderConfigFields = (
  plugins: FormPlugin[],
  fieldType: FieldPreset,
  defaults = {}
): FieldRenderConfigStructure => {
  const config = fieldsStore.allFieldsData[fieldType].metadata.renderConfig
  const plugin = _.first(plugins) // current implementation support only 1 plugin per preset, change this when we will have more than 1
  let basePluginConfig = {}

  if (config && config[FormPlugin.FORM_BUILDER]) {
    basePluginConfig = config[FormPlugin.FORM_BUILDER]
  }

  if (config && plugin && config[plugin]) {
    return _.merge({}, DEFAULT_FIELD_RENDER_CONFIG, basePluginConfig, config[plugin])
  } else {
    return _.merge({}, DEFAULT_FIELD_RENDER_CONFIG, basePluginConfig, defaults)
  }
}

export const getFieldPluginsExtraData = ({
  fieldType,
  plugins,
}: {
  fieldType: FieldPreset
  plugins: FormPlugin[]
}): Partial<FieldExtraData> => {
  const pluginsExtraData = fieldsStore.allFieldsData[fieldType].metadata.pluginsExtraData

  let extraData = {}

  if (!pluginsExtraData) {
    return extraData
  }

  _.forEach(plugins, plugin => {
    const pluginExtraData = _.get(pluginsExtraData, plugin)

    if (pluginExtraData) {
      _.merge(extraData, pluginExtraData)
    }
  })

  return extraData
}

export const allowCollectionSync = (fieldType: FieldPreset): boolean => {
  return !!!fieldsStore.allFieldsData[fieldType].metadata.disableCollectionSync
}

export const createFieldsManifests = () =>
  _.reduce(
  [...fieldsStore.recommendedFields, ...fieldsStore.generalFields, ...fieldsStore.registrationFields],
  (acc, field: IGeneralField) => {
    if (!acc[field.role]){
      acc[field.role] = { ...field.manifest, designMapping: field.designMapping }
    }
    return acc
  },
  {}
)
