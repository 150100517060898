import * as _ from 'lodash'
import { COMPONENT_TYPES } from '@wix/forms-common'
import { getFontSize } from './contact-form-style'

export const mobileSkinTranslator: {
  [key in string]: (
    contactForm: DynamicContactForm,
    convertedForm: RawComponentStructure,
    fontOptions
  ) => RawComponentStructure
} = {
  'contactform.FullWidthButtonSkin': (contactForm, convertedForm, fontOptions) => {
    const contactFormMobileLayout = contactForm.mobileStructure.layout
    const numOfInputs = convertedForm.components.filter(
      c => c.componentType === COMPONENT_TYPES.TEXT_INPUT
    ).length
    const numOfTextAreaInputs = convertedForm.components.filter(
      c => c.componentType === COMPONENT_TYPES.TEXT_AREA
    ).length
    const textInputHeight = 45
    const textAreaHeight = 150
    const messageHeight = 14
    const marginBottom = 15

    const totalTextInputSpace = numOfInputs * (textInputHeight + marginBottom)
    const totalTextAreaSpace = numOfTextAreaInputs * (textAreaHeight + marginBottom)
    const components: RawComponentStructure[] = convertedForm.components.map(component => {
      switch (component.componentType) {
        case COMPONENT_TYPES.TEXT:
          return _.merge({}, component, {
            mobileStructure: {
              layout: {
                x: 0,
                height: messageHeight,
                width: contactFormMobileLayout.width,
              },
            },
          })
        case COMPONENT_TYPES.TEXT_AREA:
          return _.merge({}, component, {
            props: { inputHeightMobile: textAreaHeight },
            mobileStructure: {
              layout: {
                x: 0,
                height: textAreaHeight,
                width: contactFormMobileLayout.width,
                scale: getScale(component, 14, fontOptions),
              },
            },
          })
        case COMPONENT_TYPES.SITE_BUTTON:
          return _.merge({}, component, {
            mobileStructure: {
              layout: {
                x: 0,
                height:
                  contactFormMobileLayout.height -
                  (messageHeight + marginBottom + totalTextInputSpace + totalTextAreaSpace),
                width: contactFormMobileLayout.width,
              },
            },
          })
        default:
          return _.merge({}, component, {
            props: { inputHeightMobile: textInputHeight },
            mobileStructure: {
              layout: {
                x: 0,
                height: textInputHeight,
                width: contactFormMobileLayout.width,
                scale: getScale(component, 14, fontOptions),
              },
            },
          })
      }
    })

    return {
      ...convertedForm,
      components: calculateOncColumnY(components, 15),
      mobileStructure: { layout: contactFormMobileLayout },
    }
  },
}

export const enhanceFormWithMobileStructure = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
  fontOptions
): RawComponentStructure => {
  const contactFormMobileLayout = _.get(contactForm.mobileStructure, 'layout')
  if (!contactFormMobileLayout) {
    return convertedForm
  }
  const translator = mobileSkinTranslator[contactForm.skin]
  const formWithConvertedComponents = translator
    ? translator(contactForm, convertedForm, fontOptions)
    : convertedForm
  return { ...formWithConvertedComponents, mobileStructure: { layout: contactFormMobileLayout } }
}

const calculateOncColumnY = (
  components: RawComponentStructure[],
  marginBottom: number
): RawComponentStructure[] => {
  const newComponents: RawComponentStructure[] = []
  components.reduce<RawComponentStructure>((prevComponent, currentComponent) => {
    const y = prevComponent
      ? prevComponent.mobileStructure.layout.y +
        prevComponent.mobileStructure.layout.height +
        marginBottom
      : 0
    const convertedComponent: RawComponentStructure = _.merge({}, currentComponent, {
      mobileStructure: {
        layout: {
          y,
        },
      },
    })

    newComponents.push(convertedComponent)
    return convertedComponent
  }, null)
  return newComponents
}

// this is sad i know https://github.com/wix-private/thunderbolt/blob/3b9bb1541b06a66ad8fd58017ea9965029f6d730/packages/thunderbolt-becky/src/functionLibraryExtensions/fonts/getMobileScaledFont.ts
const getMobileFontSize: (desktopFontSize: number) => number = desktopFontSize => {
  const MOBILE_DEFAULT_MIN_FONT_SIZE = 12
  const MOBILE_MAX_FONT_SIZE = 50
  const SCALING_FACTOR = 3
  const MIN_SCALED_FONT_SIZE = 14
  const MEDIUM_SCALED_FONT_SIZE = 26
  const MAX_SCALED_FONT_SIZE = 100
  let mobileFontSize

  const intDesktopFontSize = Math.round(desktopFontSize)

  if (intDesktopFontSize < MOBILE_DEFAULT_MIN_FONT_SIZE) {
    mobileFontSize = MOBILE_DEFAULT_MIN_FONT_SIZE
  } else if (intDesktopFontSize <= MIN_SCALED_FONT_SIZE) {
    mobileFontSize = intDesktopFontSize + 1
  } else if (intDesktopFontSize <= MEDIUM_SCALED_FONT_SIZE - 1) {
    mobileFontSize = intDesktopFontSize
  } else if (intDesktopFontSize <= MAX_SCALED_FONT_SIZE) {
    mobileFontSize = Math.floor(
      (intDesktopFontSize - MEDIUM_SCALED_FONT_SIZE) / SCALING_FACTOR + MEDIUM_SCALED_FONT_SIZE
    )
  } else {
    mobileFontSize = MOBILE_MAX_FONT_SIZE
  }
  return mobileFontSize
}

const getScale = (
  formField: RawComponentStructure,
  wantedMobileFontSize: number,
  fontOptions
): number => {
  const desktopFontSize = getFontSize(formField, fontOptions)
  const mobileFontSize = getMobileFontSize(desktopFontSize)
  return wantedMobileFontSize / mobileFontSize
}
