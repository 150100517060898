import * as _ from 'lodash'
import { ContactFormEnhancer } from './contact-form-service'
import { COMPONENT_TYPES } from '@wix/forms-common'

const fixLayout1 = (contactForm: DynamicContactForm, convertedForm: RawComponentStructure) => {
  const marginRight = 17
  const messageFieldHeight = 150
  const inputFieldHeight = 38
  const submitButtonHeight = 45
  const inputMarginBottom = 12
  const textAreaMarginBottom = 12
  const submitButtonMarginBottom = 10
  const inputTextPadding = 5
  const submitMessageHeight = 12

  let currentY = 0

  _.forEach(convertedForm.components, (field, idx) => {
    const handleComponentLayout = ({
      bottomMargin,
      height,
      textPadding,
      inputHeight,
    }: {
      bottomMargin: number
      height: number
      textPadding?: number
      inputHeight?: number
    }) => {
      if (_.includes([0, 1], idx)) {
        const firstBlockFieldWidth = (contactForm.layout.width - marginRight) / 2

        if (idx === 0) {
          field.layout = { x: 0, y: currentY, width: firstBlockFieldWidth, height }
        }

        if (idx === 1) {
          field.layout = {
            x: firstBlockFieldWidth + marginRight,
            y: currentY,
            width: firstBlockFieldWidth,
            height,
          }

          currentY = currentY + height + bottomMargin
        }
      } else {
        field.layout = { x: 0, y: currentY, width: contactForm.layout.width, height }
        currentY = currentY + height + bottomMargin
      }

      if (textPadding !== undefined) {
        field.props.textPadding = textPadding
      }

      if (inputHeight !== undefined) {
        field.props.inputHeight = inputHeight
      }
    }

    switch (field.componentType) {
      case COMPONENT_TYPES.TEXT_INPUT:
        handleComponentLayout({
          bottomMargin: inputMarginBottom,
          height: inputFieldHeight,
          textPadding: inputTextPadding,
        })
        break
      case COMPONENT_TYPES.TEXT_AREA:
        handleComponentLayout({
          bottomMargin: textAreaMarginBottom,
          height: messageFieldHeight,
          inputHeight: messageFieldHeight,
          textPadding: inputTextPadding,
        })
        break
      case COMPONENT_TYPES.SITE_BUTTON:
        handleComponentLayout({
          bottomMargin: submitButtonMarginBottom,
          height: submitButtonHeight,
        })
        break
      case COMPONENT_TYPES.TEXT:
        handleComponentLayout({
          bottomMargin: 0,
          height: submitMessageHeight,
        })
        break
    }
  })

  return convertedForm
}

export const fixLayout: ContactFormEnhancer = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure
) => {
  switch (contactForm.skin) {
    case 'contactform.FullWidthButtonSkin':
      return fixLayout1(contactForm, convertedForm)
    default:
      return convertedForm
  }
}
