import { FormPreset, ResponsiveFormPreset } from '@wix/forms-common'

export const PRESET_TYPES = [
  FormPreset.CONTACT_FORM,
  FormPreset.GET_SUBSCRIBERS,
  FormPreset.PAYMENT_FORM,
  FormPreset.MULTI_STEP_REGISTRATION_FORM,
  FormPreset.SALES_LEAD,
  FormPreset.SUPPORT_REQUEST,
  FormPreset.TESTIMONIAL,
  FormPreset.JOB_APPLICATION,
  FormPreset.CUSTOMER_SATISFACTION,
  FormPreset.PRICE_QUOTE,
  FormPreset.CONTEST_ENTRY,
  FormPreset.QUIZ,
]

export const RESPONSIVE_PRESET_TYPES = [
  {
    contentId: FormPreset.CONTACT_FORM,
    preset: ResponsiveFormPreset.CONTACT,
  },
  {
    contentId: FormPreset.GET_SUBSCRIBERS,
    preset: ResponsiveFormPreset.SUBSCRIBERS,
  },
]
