import * as _ from 'lodash'
import { FIELDS } from '../../../../../../constants/roles'
import { RegistrationFieldPreset, FormPlugin, FieldRenderConfigType } from '@wix/forms-common'
import { GeneralText } from '../../general-fields/definitions/general-text'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import {
  CATEGORIES,
  DEFAULT_CATEGORIES,
} from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'
import { EMAIL_REGEX } from '../../../../../../constants/field-validation'

export class LoginEmail extends GeneralText {
  public get customFields() {
    return []
  }

  public get allowOnlyOnce() {
    return true
  }

  public get disableCollectionSync() {
    return true
  }

  protected get icon() {
    return iconNames.email
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: { category: DEFAULT_CATEGORIES.none }
      },
      [FormPlugin.REGISTRATION_FORM]: {
        isMandatory: true,
        crmSync: FieldRenderConfigType.REMOVE,
        connectPanelRegistrationFooter: FieldRenderConfigType.ENABLED,
        required: FieldRenderConfigType.DISABLED,
        internalName: FieldRenderConfigType.DISABLED,
        checkedByDefault: FieldRenderConfigType.DISABLED,
        addFieldPanelData: { category: CATEGORIES.registration }
      },
    }
  }

  public get fieldType() {
    return RegistrationFieldPreset.REGISTRATION_FORM_LOGIN_EMAIL
  }

  public get role() {
    return FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL
  }

  public get crmType() {
    return CRM_TYPES.EMAIL
  }

  protected get data(): any {
    return {
      pattern: EMAIL_REGEX,
      textType: 'email',
      placeholder: this.translate(`preset.${_.camelCase(this.fieldType)}Placeholder`),
    }
  }

  protected get props(): any {
    return {
      required: true,
    }
  }

  public get manifest() {
    return fieldManifest({ registration: true })
  }
}
