import { Theme } from '@wix/forms-common'
import { fetchRetry } from '../../../utils/fetch-utils'
import { THEMES_COMPONENTS_STATIC_URL } from '../../../constants/resources'
import * as _ from 'lodash'

const themes: { [key: string]: Promise<any> } = {}

export const addTheme = (themeName: string, themeValue) => {
  themes[themeName] = themeValue
}

export const fetchAllThemes = ravenInstance => {
  _.values(Theme).forEach(async theme => {
    const response = await fetchRetry(ravenInstance)(
      `${THEMES_COMPONENTS_STATIC_URL}/${theme}.json`
    )
    addTheme(theme, response.json())
  })
}

export const getTheme = async (themeName: string) => {
  const theme = await themes[themeName]
  const stylesByRole = _.get(theme, 'style', {})
  const propsByRole = _.get(theme, 'props', {})
  const dataByRole = _.get(theme, 'data', {})

  return { stylesByRole, propsByRole, dataByRole }
}
