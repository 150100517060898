import * as _ from 'lodash'
import { COMPONENT_TYPES } from '@wix/forms-common'
import { innerText } from '../../../utils/utils'

type SkinTranslator = {
  defaultValues: ContactFormStyleDefaultValues
  translator: () => {
    [key in COMPONENT_TYPES]?: ContactFormStyleTranslator
  }
}

type ContactFormStyleDefaultValues = {
  properties: StyleTranslator
  propertiesSource: StyleTranslator
}
type ContactFormStyleTranslator = StyleTranslator | TextTranslator

type StyleTranslator = { [key in string]: string | number }
type TextTranslator = (
  contactFormStyle: ComponentStyle,
  contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
  text: string
) => string

const baseTextInputTranslator = {
  shd: 'shd',
  'boxShadowToggleOn-shd': 'boxShadowToggleOn-shd',
  rd: 'rd',
  fnt: 'fnt',
  fntlbl: 'fnt',
  bg: 'bg1',
  'alpha-bg': 'alpha-bg1',
  brw: 'brw',
  brwh: 'brw',
  brwf: 'brw',
  brwd: 'brw',
  brwe: 'brwe',
  brd: 'brd',
  'alpha-brd': 'alpha-brd',
  brdh: 'brd',
  'alpha-brdh': 'alpha-brd',
  brdf: 'brd',
  'alpha-brdf': 'alpha-brd',
  brdd: 'brd',
  'alpha-brdd': 'alpha-brd',
  txt: 'txt1',
  brde: 'txtError',
  'alpha-brde': 'alpha-txtError',
  bge: 'bg1',
  'alpha-bge': 'alpha-bg1',
  txt2: 'txt3',
  bgh: 'bg1',
  'alpha-bgh': 'alpha-bg1',
  bgf: 'bg1',
  'alpha-bgf': 'alpha-bg1',
  txtlbl: 'txt3',
  txtlblrq: 'txt3',
}

const baseButtonTranslator = {
  shd: 'shd',
  'boxShadowToggleOn-shd': 'boxShadowToggleOn-shd',
  rd: 'rd',
  bg: 'bg2',
  'alpha-bg': 'alpha-bg2',
  bgh: 'bg2',
  'alpha-bgh': 'alpha-bg2',
  txt: 'txt2',
  txth: 'txt2',
  fnt: 'fnt2',
}

const defaultProperties = {
  bg1: 'color_11',
  bg2: 'color_18',
  brd: 'color_15',
  brw: 0,
  fnt: 'font_7',
  fnt2: 'font_7',
  labelTxt: '#a9a9a9',
  rd: '5px',
  shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
  txt1: 'color_15',
  txt2: 'color_15',
  txt3: 'color_15',
  txtError: '#8b0000',
  txtSuccess: '#bada55',
  'alpha-txtError': 1,
  brwe: 1,
}

const defaultPropertiesSource = {
  bg1: 'theme',
  bg2: 'theme',
  brd: 'theme',
  brw: 'value',
  fnt: 'theme',
  fnt2: 'theme',
  labelTxt: 'value',
  rd: 'value',
  shd: 'value',
  txt1: 'theme',
  txt2: 'theme',
  txt3: 'theme',
  txtError: 'value',
  txtSuccess: 'value',
  'alpha-txtError': 'value',
  brwe: 'value',
}

export const skinToStyleMapping: { [key in string]: SkinTranslator } = {
  'contactform.FullWidthButtonSkin': {
    defaultValues: {
      properties: {
        ...defaultProperties,
        brw: 1,
      },
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => {
      const textInputTranslator = _.omit(baseTextInputTranslator, ['rd'])
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: _.omit(baseButtonTranslator, [
          'boxShadowToggleOn-shd',
          'shd',
          'rd',
        ]),
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'wysiwyg.viewer.skins.contactform.BasicContactFormSkin': {
    defaultValues: {
      properties: defaultProperties,
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => {
      const textInputTranslator = {
        ...baseTextInputTranslator,
        txt2: 'labelTxt',
        txtlbl: 'labelTxt',
        txtlblrq: 'labelTxt',
      }
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: baseButtonTranslator,
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'wysiwyg.viewer.skins.contactform.DefaultContactForm': {
    defaultValues: {
      properties: defaultProperties,
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => {
      const textInputTranslator = {
        ...baseTextInputTranslator,
        txt2: 'labelTxt',
        txtlbl: 'labelTxt',
        txtlblrq: 'labelTxt',
      }
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: baseButtonTranslator,
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'contactform.LineOnlySkin': {
    defaultValues: {
      properties: {
        brd: 'color_15',
        brd2: 'color_18',
        fnt: 'font_7',
        fnt2: 'font_7',
        txt1: 'color_15',
        txt3: 'color_15',
        txt4: 'color_15',
        txtError: '#FF0000',
        txtSuccess: '#bada55',
        brw: '0px 0px 1px 0px',
        bg1: '#FFFFFF',
        'alpha-bg1': 0,
      },
      propertiesSource: {
        brd: 'theme',
        brd2: 'theme',
        fnt: 'theme',
        fnt2: 'theme',
        txt1: 'theme',
        txt3: 'theme',
        txt4: 'theme',
        txtError: 'value',
        txtSuccess: 'value',
        brw: 'value',
        bg1: 'value',
        'alpha-bg1': 'value',
      },
    },
    translator: () => {
      const textInputTranslator = {
        fnt: 'fnt',
        fntlbl: 'fnt',
        brw: 'brw',
        brwh: 'brw',
        brwf: 'brw',
        brwd: 'brw',
        brwe: 'brw',
        brd: 'brd',
        'alpha-brd': 'alpha-brd',
        brdh: 'brd',
        'alpha-brdh': 'alpha-brd',
        brdf: 'brd2',
        'alpha-brdf': 'alpha-brd2',
        txt: 'txt1',
        txtlbl: 'txt3',
        txtlblrq: 'txt3',
        txt2: 'txt3',
        brde: 'txtError',
        bge: 'bg1',
        'alpha-bge': 'alpha-bg1',
        bgh: 'bg1',
        'alpha-bgh': 'alpha-bg1',
        bgf: 'bg1',
        'alpha-bgf': 'alpha-bg1',
        bg: 'bg1',
        'alpha-bg': 'alpha-bg1',
      }
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: {
          bg: 'bg1',
          'alpha-bg': 'alpha-bg1',
          bgh: 'bg1',
          'alpha-bgh': 'alpha-bg1',
          txt: 'txt4',
          txth: 'txt4',
          fnt: 'fnt2',
        },
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft': {
    defaultValues: {
      properties: defaultProperties,
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => ({
      [COMPONENT_TYPES.TEXT_INPUT]: baseTextInputTranslator,
      [COMPONENT_TYPES.TEXT_AREA]: baseTextInputTranslator,
      [COMPONENT_TYPES.SITE_BUTTON]: baseButtonTranslator,
      [COMPONENT_TYPES.TEXT]: translateMessage,
    }),
  },
  'wysiwyg.viewer.skins.contactform.VerticalForm': {
    defaultValues: {
      properties: defaultProperties,
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => ({
      [COMPONENT_TYPES.TEXT_INPUT]: baseTextInputTranslator,
      [COMPONENT_TYPES.TEXT_AREA]: baseTextInputTranslator,
      [COMPONENT_TYPES.SITE_BUTTON]: baseButtonTranslator,
      [COMPONENT_TYPES.TEXT]: translateMessage,
    }),
  },
  'contactform.FieldAnimationSkin': {
    defaultValues: {
      properties: {
        bg1: 'color_11',
        bg2: 'color_18',
        bg3: 'color_11',
        fnt: 'font_7',
        fnt2: 'font_7',
        txt1: 'color_15',
        txt2: 'color_15',
        txt4: 'color_15',
        txtError: '#8b0000',
        txtSuccess: '#bada55',
        brw: 0,
      },
      propertiesSource: {
        bg1: 'theme',
        bg2: 'theme',
        bg3: 'theme',
        fnt: 'theme',
        fnt2: 'theme',
        txt1: 'theme',
        txt2: 'theme',
        txt4: 'theme',
        txtError: 'value',
        txtSuccess: 'value',
        brw: 'value',
      },
    },
    translator: () => {
      const textInputTranslator = {
        fnt: 'fnt',
        fntlbl: 'fnt',
        brw: 'brw',
        brwh: 'brw',
        brwf: 'brw',
        brwd: 'brw',
        brwe: 'brw',
        txt: 'txt1',
        txtlbl: 'txt4',
        txtlblrq: 'txt4',
        txt2: 'txt4',
        bge: 'bg3',
        'alpha-bge': 'alpha-bg3',
        bgh: 'bg3',
        'alpha-bgh': 'alpha-bg3',
        bgf: 'bg1',
        'alpha-bgf': 'alpha-bg1',
        bg: 'bg3',
        'alpha-bg': 'alpha-bg3',
      }
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: {
          bg: 'bg2',
          'alpha-bg': 'alpha-bg2',
          bgh: 'bg2',
          'alpha-bgh': 'alpha-bg2',
          txt: 'txt2',
          txth: 'txt2',
          fnt: 'fnt2',
        },
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'contactform.OverlappingButtonSkin': {
    defaultValues: {
      properties: {
        bg1: 'color_11',
        bg2: 'color_18',
        brd: 'color_15',
        brw: 1,
        brw2: 1,
        fnt: 'font_7',
        fnt2: 'font_7',
        shd: '0 0px 0px rgba(0, 0, 0, 0)',
        txt1: 'color_15',
        txt2: 'color_15',
        labelTxt: 'color_15',
        txtSuccess: '#bada55',
      },
      propertiesSource: {
        bg1: 'theme',
        bg2: 'theme',
        brd: 'theme',
        brw: 'value',
        brw2: 'value',
        fnt: 'theme',
        fnt2: 'theme',
        shd: 'value',
        txt1: 'theme',
        txt2: 'theme',
        txt3: 'theme',
        txtError: 'value',
        txtSuccess: 'value',
      },
    },
    translator: () => {
      const textInputTranslator = _.omit(
        {
          ...baseTextInputTranslator,
          txt2: 'labelTxt',
          txtlbl: 'labelTxt',
          txtlblrq: 'labelTxt',
          brde: 'brd',
          brwe: 'brw',
        },
        ['rd']
      )
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: {
          bg: 'bg2',
          'alpha-bg': 'alpha-bg2',
          bgh: 'bg2',
          'alpha-bgh': 'alpha-bg2',
          txt: 'txt2',
          txth: 'txt2',
          fnt: 'fnt2',
          brw: 'brw2',
          brd: 'brd',
          'alpha-brd': 'alpha-brd',
          brdh: 'brd',
          'alpha-brdh': 'alpha-brd',
          brdd: 'brd',
          'alpha-brdd': 'alpha-brd',
        },
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin': {
    defaultValues: {
      properties: defaultProperties,
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => {
      const textInputTranslator = {
        ...baseTextInputTranslator,
        txt2: 'labelTxt',
        txtlbl: 'labelTxt',
        txtlblrq: 'labelTxt',
      }
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: baseButtonTranslator,
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
}

const translateMessage = (
  contactFormStyle: ComponentStyle,
  contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
  text: string
) => {
  const fontSize = '14px'
  const fontFamily = 'helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif'
  const { properties, propertiesSource } = getKeyValue(
    contactFormStyle,
    contactFormStyleDefaultValues,
    'txtSuccess'
  )
  const colorSpan =
    propertiesSource === 'theme'
      ? `<span class="${properties}">`
      : `<span style="color:${properties};">`
  return `<p style="font-size: ${fontSize};">${colorSpan}<span style="font-family:${fontFamily};"><span style="font-size:${fontSize};">${text}</span></span></span></p>`
}

const getKeyValue = (
  style: ComponentStyle,
  defaultValues: ContactFormStyleDefaultValues,
  key: string
) => ({
  properties: _.get(style, ['style', 'properties', key], defaultValues.properties[key]),
  propertiesSource: _.get(
    style,
    ['style', 'propertiesSource', key],
    defaultValues.propertiesSource[key]
  ),
})

export const translateContactFormStyle = (
  contactFormStyleTranslator: ContactFormStyleTranslator,
  contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
  contactFormStyle: ComponentStyle,
  componentStructure: RawComponentStructure
): RawComponentStructure => {
  if (componentStructure.componentType === COMPONENT_TYPES.TEXT) {
    return {
      ...componentStructure,
      data: {
        ...componentStructure.data,
        text: (contactFormStyleTranslator as TextTranslator)(
          contactFormStyle,
          contactFormStyleDefaultValues,
          innerText(componentStructure.data.text)
        ),
      },
    }
  } else {
    const updatedStyle: ComponentStyle = _.reduce(
      contactFormStyleTranslator,
      (prevStyle, contactFormStyleKey, wixFormsStyleKey) => {
        const { properties, propertiesSource } = getKeyValue(
          contactFormStyle,
          contactFormStyleDefaultValues,
          contactFormStyleKey
        )
        prevStyle.style.properties[wixFormsStyleKey] = properties
        prevStyle.style.propertiesSource[wixFormsStyleKey] = propertiesSource
        return prevStyle
      },
      _.merge(
        {},
        {
          style: {
            propertiesSource: {},
          },
        },
        <ComponentStyle>componentStructure.style
      )
    )
    return { ...componentStructure, style: updatedStyle }
  }
}

export const getFontSize = (formField: RawComponentStructure, fontMap): number => {
  let fontValue: string = (<ComponentStyle>formField.style).style.properties.fnt
  if (fontMap[fontValue]) {
    fontValue = fontMap[fontValue].size
  }
  const regexResult = new RegExp(/(\d+)px/gi).exec(fontValue)
  return Number(regexResult[1])
}
